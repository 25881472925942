import React from 'react';

const IconLogo = () => (
  <svg width="43.2" height="37.8" viewBox="0 0 43.2 37.8" xmlns="http://www.w3.org/2000/svg">
    <g
      id="svgGroup"
      strokeLinecap="round"
      fillRule="evenodd"
      fontSize="1pt"
      stroke="currentColor"
      strokeWidth="0.25mm"
      fill="currentColor">
      <path
        d="M 16.15 11.05 L 20.775 11.05 L 8 22.925 L 22.325 37.3 L 17.85 37.3 L 3.625 22.975 L 16.15 11.05 Z M 0
      0.425 L 3.1 0 L 3.1 37.3 L 0 37.3 L 0 0.425 Z M 35.345 32.261 A 4.151 4.151 0 0 0 35.1 33.7 A 4.932 4.932 0 0 0
      35.103 33.878 A 4.156 4.156 0 0 0 35.625 35.788 Q 36.15 36.725 37.05 37.263 Q 37.95 37.8 39.1 37.8 Q 40.3 37.8
      41.225 37.263 Q 42.15 36.725 42.675 35.788 A 4.031 4.031 0 0 0 42.952 35.181 A 4.344 4.344 0 0 0 43.2 33.7 A 4.733
       4.733 0 0 0 43.198 33.552 A 3.977 3.977 0 0 0 42.675 31.662 Q 42.15 30.75 41.225 30.225 A 3.883 3.883 0 0 0
       40.297 29.848 A 4.665 4.665 0 0 0 39.1 29.7 A 4.752 4.752 0 0 0 38.678 29.718 A 3.87 3.87 0 0 0 37.05 30.225
       Q 36.15 30.75 35.625 31.663 A 3.863 3.863 0 0 0 35.345 32.261 Z"
        vectorEffect="non-scaling-stroke"
      />
    </g>
  </svg>
);

export default IconLogo;
